import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ContactFrom from "../components/contactForm"
import ContactMapRow from "../components/contactMapRow"

const Kontakt = ({ data }) => {
  return (
    <Layout>
      <Seo title="Kontakt" />

      <section className="contact-main">
        <div className="container-fluid">
          <h1>Kontakt</h1>
          <ContactMapRow data={data?.contact?.acfContact} />

          <div className="contact-main--info">
            <div className="row">
              <div className="col-md-3">
                <h3>Kontaktinfo</h3>
                <h4>Garda Sikring AS, avd. Nor-Netting</h4>
                <address className="ico ico-address">
                  Stamveien 8, 1481 Hagan
                </address>
                <a href="mailto:nor-netting@gardasikring.no" className="ico ico-email">
                  nor-netting@gardasikring.no
                </a>{" "}
                <br />
                <a href="tel:+4723175240" className="ico ico-phone">
                  Tlf +47 23 17 52 40
                </a>{" "}
                <br />
                <br />
                <a
                  className="ico ico-pdf"
                  href="http://gardasikring.no/wp-content/uploads/2020/04/Salgs_og_leveringsbetingelser_2020.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Våre salgs- og leveringsbetingelser
                </a>
              </div>
              <div className="col-md-9">
                <h3>Send oss en henvendelse</h3>
                <div className="anchor" id="form"></div>
                <ContactFrom />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    contact: wpPage(id: { eq: "cG9zdDo0Mg==" }) {
      acfContact {
        header
        content
        persons {
          image {
            localFile {
              publicURL
            }
          }
          fullName
          email
          phone
          position
        }
      }
    }
  }
`

export default Kontakt
